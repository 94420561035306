<template>
	<v-tree v-if="nav.length">
		<v-tree-node v-for="(item, i) in nav" :key="i" :item="item" />
	</v-tree>
</template>

<script lang="ts" setup>
	import type { TreeItem } from '@/components/common/tree/tree-item.vue'

	const { logout } = useUserStore()

	const localePath = useLocalePath()

	const nav: TreeItem[] = [
		{
			title: 'Account Details',
			link: localePath('/account'),
		},
		{
			title: 'Addresses',
			link: localePath('/account/addresses'),
		},
		{
			title: 'Orders',
			link: localePath('/account/orders'),
		},
		{
			title: 'Logout',
			command: async () => {
				const { success } = await logout()
				if (success) navigateTo(localePath('/'))
			},
		},
	]
</script>

<style lang="scss"></style>
